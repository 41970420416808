import { CodeSandboxOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { PATH_3D_MODEL, PATH_3D_MODEL_ADD, PATH_3D_MODEL_DETAIL } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const ModelList = lazy(() => import('../../modules/3d-model/3d-model.screen'));
// 84803: uncomment upload
const ModelAdd = lazy(() => import('../../modules/3d-model/3d-model-add.screen'));
const ModelDetail = lazy(() => import('../../modules/3d-model/3d-model-detail.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const ModelRoute: IRoute = {
    path: PATH_3D_MODEL,
    name: '3d_model.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <CodeSandboxOutlined />,
    routes: [
        {
            path: PATH_3D_MODEL,
            name: '3d_model.list',
            component: ModelList,
            exact: true,
            role: Roles.Admin
        },
        // 84803: uncomment upload
        {
            path: PATH_3D_MODEL_ADD,
            name: '3d_model.add',
            component: ModelAdd,
            exact: true,
            hideInMenu: true,
            role: Roles.Admin
        },
        {
            path: PATH_3D_MODEL_DETAIL,
            name: '3d_model.detail',
            component: ModelDetail,
            exact: true,
            hideInMenu: true,
            role: Roles.Admin
        }
    ]
};
