import React, { Suspense } from 'react';
import { Result, Button } from 'antd';
import { AsyncStorageUtils } from 'utils/async-storage.utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Forbidden: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const onClick = () => {
        AsyncStorageUtils.clear();
        history.push('/auth/login');
    };

    return (
        <Suspense fallback="">
            <main>
                <Result
                    status="403"
                    title="403"
                    subTitle={t('sorry_not_allow_access_for_this_page')}
                    extra={
                        <>
                            <Button
                                onClick={onClick}
                                type="primary"
                                style={{ background: '#001529', borderColor: '#001529' }}
                            >
                                {t('sign_in_with_another_account')}
                            </Button>
                        </>
                    }
                />
            </main>
        </Suspense>
    );
};

export default Forbidden;
