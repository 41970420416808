/* eslint-disable */
import { createContext, useReducer } from 'react';

interface IStateTypes {
    [x: string]: any;
}

interface IActionTypes {
    type: 'ALL' | 'SPOT' | 'VPS_OBJECT' | 'GIFT_EXCHANGE_PLACE' | 'INIT_MODELS';
    payload: {
        data: any;
    };
}

const initialState: IStateTypes = {
    name: '',
    description: '',
    address: '',
    latitude: '',
    longitude: '',
    images: {
        url: []
    },
    radius: 1,
    isFree: false,// 85134: add checkbox free: default
    shareTemplate: '',
    models: [],
    modelId: '',
    characters: [],
    characterId: '',
    vpsObject: [],
    giftExchangePlace: []
};

const reducer = (state: IStateTypes, action: IActionTypes) => {
    switch (action.type) {
        case 'ALL':
            return { ...state, ...action.payload.data };
        case 'SPOT':
            return { ...state, ...action.payload.data };
        case 'VPS_OBJECT':
            return { ...state, vpsObject: action.payload.data };
        case 'GIFT_EXCHANGE_PLACE':
            return { ...state, giftExchangePlace: action.payload.data };
        case 'INIT_MODELS':
            return { ...initialState, ...action.payload.data };
        default:
            throw new Error();
    }
};

const useValue = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return {
        state,
        dispatch
    };
};

/* eslint-disable-next-line */
export const SpotAddContext = createContext({} as ReturnType<typeof useValue>);

export const SpotAddProvider = (props: any) => {
    return <SpotAddContext.Provider value={useValue()}>{props.children}</SpotAddContext.Provider>;
};
