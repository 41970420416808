import { UserOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { PATH_GUEST, PATH_GUEST_DETAIL, PATH_GUEST_EDIT } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const GuestList = lazy(() => import('../../modules/guest/guest.screen'));
const GuestAdd = lazy(() => import('../../modules/guest/guest-add.screen'));
const GuestDetail = lazy(() => import('../../modules/guest/guest-detail.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const GuestRoute: IRoute = {
    path: PATH_GUEST,
    name: 'guest.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <UserOutlined />,
    routes: [
        {
            path: PATH_GUEST,
            name: 'guest.list',
            component: GuestList,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_GUEST_DETAIL,
            name: 'guest.detail',
            component: GuestDetail,
            exact: true,
            hideInMenu: true,
            role: Roles.Admin
        }
    ]
};
