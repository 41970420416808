import React from 'react';
import { Redirect } from 'react-router-dom';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';
import { AsyncStorageUtils, StorageKey } from 'utils/async-storage.utils';

export const hasRoles = (role?: Roles) => {
    if (!role) return true;

    let userInfo = AsyncStorageUtils.get(StorageKey.USER_INFO) as any;
    userInfo = userInfo && typeof userInfo === 'string' ? JSON.parse(userInfo) : null;

    return userInfo && userInfo?.role === role;
};
/**
 *
 * @param role
 */
export const withRole = (Component: React.ElementType, role?: Roles, routes?: Array<IRoute>) =>
    hasRoles(role) ? <Component routes={routes} /> : <Redirect to={'/forbidden'} />;
