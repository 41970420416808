/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Result, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Result
            status="404"
            title="404"
            subTitle={'404'}
            extra={
                <>
                    <Typography.Link href="/spot">
                        <Button
                            type="primary"
                            style={{ background: '#001529', borderColor: '#001529' }}
                        >
                            {t('spot.menu')}
                        </Button>
                    </Typography.Link>
                </>
            }
        />
    );
};

export default NotFound;
