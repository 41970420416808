import { default as React, Fragment, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import MasterRoutes from 'routing/master.route';
import RouteConfigs from 'routing/config.route';
import { SpotAddProvider } from 'context/spot-add';
import { LoadingProvider } from 'context/loading';
import { AsyncStorageUtils, StorageKey } from 'utils/async-storage.utils';
import { GlobalDebug } from 'utils/remove-consoles';

const App: React.FC<any> = () => {
    const I18N_DEFAULT_LNG = AsyncStorageUtils.get(StorageKey.LOCACLE) || 'ja';
    if (I18N_DEFAULT_LNG) i18next.changeLanguage(`${I18N_DEFAULT_LNG}`);

    useEffect(() => {
        GlobalDebug(false, true);
    }, []);

    return (
        <Fragment>
            {/* @ts-ignore */}
            <Router>
                <LoadingProvider>
                    <SpotAddProvider>
                        <MasterRoutes routes={RouteConfigs} />
                    </SpotAddProvider>
                </LoadingProvider>
            </Router>
        </Fragment>
    );
};

export default App;
