import { GiftOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { PATH_GIFT_EXCHANGE } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const GiftList = lazy(() => import('../../modules/gift-exchange/gift-exchange.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const GiftRoute: IRoute = {
    path: PATH_GIFT_EXCHANGE,
    name: 'gift_exchange.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <GiftOutlined />,
    role: Roles.Admin,
    routes: [
        {
            path: PATH_GIFT_EXCHANGE,
            name: 'gift_exchange.list',
            component: GiftList,
            exact: true,
            role: Roles.Admin
        }
    ]
};
