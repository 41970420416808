import { EnvironmentOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { PATH_SPOT, PATH_SPOT_ADD, PATH_SPOT_EDIT } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const SpotList = lazy(() => import('../../modules/spot/spot.screen'));
const SpotAdd = lazy(() => import('../../modules/spot/spot-add.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const SpotRoute: IRoute = {
    path: PATH_SPOT,
    name: 'spot.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <EnvironmentOutlined />,
    routes: [
        {
            path: PATH_SPOT,
            name: 'spot.list',
            component: SpotList,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_SPOT_ADD,
            name: 'spot.add',
            component: SpotAdd,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_SPOT_EDIT,
            name: 'spot.edit',
            component: SpotAdd,
            exact: true,
            hideInMenu: true,
            role: Roles.Admin
        }
    ]
};
