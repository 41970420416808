export interface BaseEvent {
    type: EventBusName;
}

export interface BaseEventPayload<Payload> {
    type: EventBusName;
    payload: Payload;
}

export enum EventBusName {
    LOGOUT_EVENT
}
