import { GiftOutlined } from '@ant-design/icons';
import { lazy } from 'react';
import { PATH_GIFT_EXCHANGE_PLACE, PATH_GIFT_EXCHANGE_PLACE_ADD, PATH_GIFT_EXCHANGE_PLACE_EDIT } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const SpotList = lazy(() => import('../../modules/gift-exchange-place/gift-exchange-place.screen'));
const SpotAdd = lazy(() => import('../../modules/gift-exchange-place/gift-exchange-place-add.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const GiftExchangePlaceRoute: IRoute = {
    path: PATH_GIFT_EXCHANGE_PLACE,
    name: 'gift-exchange-place.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <GiftOutlined />,
    routes: [
        {
            path: PATH_GIFT_EXCHANGE_PLACE,
            name: 'gift-exchange-place.list',
            component: SpotList,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_GIFT_EXCHANGE_PLACE_ADD,
            name: 'gift-exchange-place.add',
            component: SpotAdd,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_GIFT_EXCHANGE_PLACE_EDIT,
            name: 'gift-exchange-place.edit',
            component: SpotAdd,
            exact: true,
            hideInMenu: true,
            role: Roles.Admin
        }
    ]
};
