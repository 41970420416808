import React, { lazy } from 'react';
import { PATH_USAGE } from 'routing/paths';
import { IRoute } from 'types/Route';

const Usage = lazy(() => import('../../modules/usage/usage.screen'));
const BlankLayout = lazy(() => import('../../libraries/layouts/blank.layout'));

export const UsageRoute: IRoute = {
    path: PATH_USAGE,
    name: 'usage.menu',
    hideInMenu: true,
    component: BlankLayout,
    routes: [
        {
            path: PATH_USAGE,
            name: 'usage.list',
            component: Usage,
            exact: true
        }
    ]
};
