// auth
export const LOGIN = '/login';
export const PATH_HOME = '/';
export const PATH_AUTH_LOGIN = '/auth/login';
export const PATH_AUTH_FORGOT_PASSWORD = '/auth/forgot-password';

// page
export const PATH_SPOT = '/spot';
export const PATH_SPOT_ADD = '/spot/add';
export const PATH_SPOT_EDIT = '/spot/edit/:id';

export const PATH_GIFT_EXCHANGE_PLACE = '/gift-exchange-place';
export const PATH_GIFT_EXCHANGE_PLACE_ADD = '/gift-exchange-place/add';
export const PATH_GIFT_EXCHANGE_PLACE_EDIT = '/gift-exchange-place/edit/:id';

export const PATH_3D_MODEL = '/3d-model';
export const PATH_3D_MODEL_ADD = '/3d-model/add';
export const PATH_3D_MODEL_DETAIL = '/3d-model/detail/:id';

export const PATH_2D_CHARACTER = '/2d-character';
export const PATH_2D_CHARACTER_ADD = '/2d-character/add';
export const PATH_2D_CHARACTER_EDIT = '/2d-character/edit/:id';

export const PATH_GUEST = '/guest';
export const PATH_GUEST_EDIT = '/guest/edit/:id';
export const PATH_GUEST_DETAIL = '/guest/:id';

export const PATH_GIFT_EXCHANGE = '/gift';

export const PATH_STAFF_GIFT_EXCHANGE = '/staff/gift';

export const PATH_USAGE = '/usage';
