import { TableOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { PATH_2D_CHARACTER, PATH_2D_CHARACTER_ADD, PATH_2D_CHARACTER_EDIT } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const CharacterList = lazy(() => import('../../modules/2d-character/2d-character.screen'));
const CharacterAdd = lazy(() => import('../../modules/2d-character/2d-character-add.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const CharacterRoute: IRoute = {
    path: PATH_2D_CHARACTER,
    name: '2d_character.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <TableOutlined />,
    routes: [
        {
            path: PATH_2D_CHARACTER,
            name: '2d_character.list',
            component: CharacterList,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_2D_CHARACTER_ADD,
            name: '2d_character.add',
            component: CharacterAdd,
            exact: true,
            role: Roles.Admin
        },
        {
            path: PATH_2D_CHARACTER_EDIT,
            name: '2d_character.edit',
            component: CharacterAdd,
            exact: true,
            hideInMenu: true,
            role: Roles.Admin
        }
    ]
};
