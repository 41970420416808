import { lazy } from 'react';
import { IRoute } from 'types/Route';
import { PATH_HOME } from '.';
import {
    AuthRoute,
    // 87035: gift exchange place
    CharacterRoute,
    // GiftRoute,
    GiftExchangePlaceRoute,
    GuestRoute,
    ModelRoute,
    SpotRoute,
    StaffGiftRoute,
    UsageRoute
} from './routes';

const MainLayout = lazy(() => import('../libraries/layouts/main.layout'));
// const HomeScreen = lazy(() => import('../modules/home/home.screen'));

const RouteConfigs: IRoute[] = [
    AuthRoute,
    StaffGiftRoute,
    UsageRoute,
    {
        path: PATH_HOME,
        name: 'menu_home',
        component: MainLayout,
        hideInMenu: true,
        routes: [
            // {
            //     path: PATH_HOME,
            //     name: 'menu_home',
            //     icon: <HomeOutlined />,
            //     exact: true,
            //     component: HomeScreen,
            //     role: Roles.Admin
            // },
            SpotRoute,
            // 87035: gift exchange place
            GiftExchangePlaceRoute,
            ModelRoute,
            CharacterRoute,
            GuestRoute,
            // 84799: hidden menu 'ギフト交換'
            // GiftRoute
        ]
    }
];

export default RouteConfigs;
