import React, { lazy } from 'react';
import { PATH_STAFF_GIFT_EXCHANGE } from 'routing/paths';
import { IRoute } from 'types/Route';
import { Roles } from 'types/roles';

const GiftList = lazy(() => import('../../modules/staff-gift-exchange/staff-gift-exchange.screen'));
const StaffLayout = lazy(() => import('../../libraries/layouts/staff.layout'));

export const StaffGiftRoute: IRoute = {
    path: PATH_STAFF_GIFT_EXCHANGE,
    name: 'gift_exchange.menu',
    hideInMenu: true,
    component: StaffLayout,
    routes: [
        {
            path: PATH_STAFF_GIFT_EXCHANGE,
            name: 'gift_exchange.list',
            component: GiftList,
            exact: true,
            role: Roles.Staff
        }
    ]
};
